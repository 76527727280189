import styled, { keyframes } from 'styled-components'

const GradientKeyframes = keyframes`
0% {
  background-position: 0% 0%;
}
50% {
  background-position: 100% 100%;
}
100% {
  background-position: 0% 0%;
}
`

const PinkGradient = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: -1;
  background-image: ${({ desktop }) =>
    (desktop &&
      'linear-gradient(72.04deg, #c0ddff 7.32%, #fab1dd 49.95%, #c4b1fa 92.61%)') ||
    'linear-gradient(72.04deg, #c0ddff 23.32%, #fab1dd 49.95%, #c4b1fa 76.61%)'};
  background-size: ${({ desktop }) => (desktop && '150%') || '200%'};

  -webkit-animation: ${GradientKeyframes} 4s ease infinite;
  -moz-animation: ${GradientKeyframes} 4s ease infinite;
  -o-animation: ${GradientKeyframes} 4s ease infinite;
  animation: ${GradientKeyframes} 4s ease infinite;
`

export const TransparentGradient = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  background-image: linear-gradient(
    90deg,
    rgba(194, 104, 233, 0.08) 0%,
    rgba(19, 18, 20, 0) 50%,
    rgba(86, 81, 252, 0.1) 100%
  );
`

export default PinkGradient
