import * as React from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import styled, { keyframes, css } from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import SEO from '../components/seo'
import PinkGradient, { TransparentGradient } from '../components/gradient'
import Socials from '../components/socials'
import useInterval from '../components/useInterval'
import { Waitlist as WaitlistAPI } from '../scripts/waitlist'
import { isSafari } from 'react-device-detect'
import dollars from '../images/dollars.min.svg'
import '../index.css'

// styles
const Page = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #121111;
  color: #ffffff;
`

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 62vh;
  padding-left: 0;
  padding-right: 0;
`

const DollarsScaleIn = keyframes`
0% {
  transform: scale(1);
}
100% {
  transform: scale(100);
}
`

const DollarsScaleOut = keyframes`
0% {
  transform: scale(1);
}
1% {
  transform: scale(100);
}
100% {
  transform: scale(1);
}
`

const DollarsAnim = keyframes`
0% {
  transform: scale(1);
}
12% {
  transform: scale(0.7);
}
25% {
  transform: scale(0.8);
}
37% {
  transform: scale(0.7);
}
50% {
  transform: scale(0.8);
}
62% {
  transform: scale(0.7);
}
75% {
  transform: scale(0.8);
}
87% {
  transform: scale(0.7);
}
100% {
  transform: scale(1);
}
`

const DollarsAnimSafari = keyframes`
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.1);
}
100% {
  transform: scale(1);
}
`

const mixinScaleIn = css`
  animation: ${DollarsScaleIn} 0.4s forwards;
`

const mixinScaleOut = css`
  animation: ${DollarsScaleOut} 0.4s forwards;
`

const mixinPulse = safari =>
  safari === 1
    ? css`
        animation: ${DollarsAnimSafari} 4s infinite;
      `
    : css`
        animation: ${DollarsAnim} 16s infinite;
      `

const DollarsImage = styled(dollars)`
  z-index: 0;
  will-change: transform;

  ${props =>
    props.safari === 0
      ? css`
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          &:hover {
            filter: drop-shadow(0.1px 3px 25px rgba(150, 100, 200, 0.3));
          }
        `
      : null};

  ${props =>
    props.scale != null && props.scale === 'in'
      ? mixinScaleIn
      : props.scale != null && props.scale === 'out'
      ? mixinScaleOut
      : mixinPulse(props.safari)};
`

const Feature = styled.div`
  transform: translateZ(0);
  text-align: center;
`

const FeatureCopy = styled.h2`
  position: fixed;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2.25em;
`

const MonologueCopy = styled.h6`
  float: right;
  text-align: right;
  opacity: ${({ fade }) => (fade && '0') || '1'};
  transition: 500ms all ease;
  will-change: opacity;
`

const WaitlistWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 20em;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Waitlist = styled.div`
  margin-top: 1.5em;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.8);
  transition: box-shadow 0.7s ease-out;
  border-radius: 2em;
  justify-content: center;
  align-content: center;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1.75em;
  padding-right: 1.75em;
  cursor: pointer;
`
const WaitlistCopy = styled.h5`
  padding: 0;
  margin: 0;
  font-size: 1em;
  text-align: center;
  color: #171b1b;
`

const WaitlistView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const WaitlistAPIWrapper = styled.div`
  color: #171b1b;
  width: ${({ desktop }) => (desktop && 'auto') || '90%'};
  min-height: ${({ desktop }) => (desktop && '30%') || '80%'};
  height: ${({ desktop }) => (desktop && 'auto') || '70%'};
  & > * {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  & > * > * {
    padding-top: 2em;
    height: 100% !important;
    width: ${({ desktop }) => (desktop && '40em') || '100%'};
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  & > * > * > div > span {
    padding-top: 2em;
  }
`

const WaitlistReturn = styled.div`
  color: #ffffff;
  cursor: pointer;
  margin-left: ${({ desktop }) => (desktop && '0px') || '5%'};
  width: 40em;
  align-self: ${({ desktop }) => (desktop && 'center') || 'flex-start'};
  margin-bottom: 1.5em;
  & > h4 {
    margin: ${({ desktop }) =>
      (desktop && '2.75rem 0 1.05rem') || '0px 0px 0px'};
  }
`

// markup
const DisclaimerWrapper = styled.div`
  position: absolute;
  bottom: 0.5em;
  left: ${({ desktop }) => (desktop && '25%') || '5%'};
  width: ${({ desktop }) => (desktop && '50%') || '90%'};
  min-height: 10%;
  justify-content: center;
  align-items: center;
`
const DisclaimerSubtitle = styled.p`
  width: 100%;
  text-align: center;
  font-size: 0.89em;
  font-weight: 500;
  line-height: 1em;
  margin-bottom: 0;
  color: #ffffff;
`
const DisclaimerCopy = styled.p`
  margin-top: 0.7em;
  width: ${({ desktop }) => (desktop && '66%') || '100%'};
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  letter-spacing: 0.01em;
  font-size: ${({ desktop }) => (desktop && '0.8em') || '0.7em'};
  line-height: 1.5em;
  color: #ffffff;
`
const DisclaimerLink = styled.a`
  text-decoration: underline;
  color: #ffffff;
`

function RegulatoryDisclaimer(props) {
  const { breakpoints } = props
  return (
    <DisclaimerWrapper desktop={breakpoints.desktop}>
      {breakpoints.desktop ? (
        <DisclaimerSubtitle>
          we're umber (umber money limited), a registered company in england and
          wales (reg. 12208296).
        </DisclaimerSubtitle>
      ) : null}

      <DisclaimerCopy desktop={breakpoints.desktop}>
        umber is pre-launch, and does not currently offer any financial products
        or services.
        <br />
        that means we are <u>not</u> regulated or authorised by the{' '}
        <DisclaimerLink href={'https://www.fca.org.uk/'}>
          financial conduct authority
        </DisclaimerLink>
        ,{' '}
        <DisclaimerLink href={'https://www.sec.gov/'}>
          u.s. securities and exchange commission
        </DisclaimerLink>
        , or any other regulator.
      </DisclaimerCopy>
    </DisclaimerWrapper>
  )
}

export default function IndexPage({ data }) {
  const [isWaitlistView, setWaitlistView] = React.useState(false)
  const [isScaling, setScaling] = React.useState(null)
  const breakpoints = useBreakpoint()

  React.useEffect(() => {
    if (isScaling != null && isScaling === 'in') {
      new Promise(resolve => setTimeout(resolve, 400)).finally(() =>
        setWaitlistView(true),
      )
    }
    if (isScaling != null && isScaling === 'out') {
      setWaitlistView(false)
      new Promise(resolve => setTimeout(resolve, 400)).finally(() =>
        setScaling(null),
      )
    }
  }, [isScaling, isWaitlistView])

  return (
    <>
      <SEO
        title="umber"
        description="a new way to manage your money, landing 2021."
      />
      {isWaitlistView ? (
        <>
          <PinkGradient desktop={breakpoints.desktop} />
          <WaitlistView>
            <WaitlistReturn
              onClick={() => setScaling('out')}
              desktop={breakpoints.desktop}>
              <h4>&#xab; go back</h4>
            </WaitlistReturn>
            <WaitlistAPIWrapper desktop={breakpoints.desktop}>
              <WaitlistAPI
                api_key={process.env.WAITLIST_KEY}
                waitlist_link="https://umber.co"
                joinWaitlistHeading="join the waitlist"
                joinWaitlistButton="lets goooo"
              />
            </WaitlistAPIWrapper>
          </WaitlistView>
        </>
      ) : (
        <>
          <Page desktop={breakpoints.desktop}>
            <TransparentGradient />
            <DollarsImage
              safari={isSafari ? 1 : 0}
              scale={isScaling}
              onClick={() => setScaling('in')}
              alt="umber dollars logo"
            />
          </Page>
          {!breakpoints.desktop ? (
            <RegulatoryDisclaimer breakpoints={breakpoints} />
          ) : null}
        </>
      )}
      {breakpoints.desktop ? (
        <RegulatoryDisclaimer breakpoints={breakpoints} />
      ) : null}
    </>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "preview.png" }) {
      childImageSharp {
        fluid(maxWidth: 1150, quality: 100, maxHeight: 1293) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
