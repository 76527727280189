import * as React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import '../index.css'

// styles
const Page = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    72.04deg,
    #c0ddff 7.32%,
    #fab1dd 49.95%,
    #c4b1fa 92.61%
  );
  height: 100%;
  width: 100%;
  color: #ffffff;
`

const Copy = styled.h2`
  text-align: center;
  margin-bottom: -0.2em;
  letter-spacing: -0.02em;
`

// markup
const NotFoundPage = () => {
  return (
    <Page>
      <Helmet>
        <title>not found</title>
      </Helmet>
      <Copy>alexa play lost by frank ocean</Copy>
      <p>404 not found</p>
    </Page>
  )
}

export default NotFoundPage
